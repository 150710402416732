@import "reset.css";

html {
  font-size: 10px;
}

.monochrome {
  filter: grayscale(100%);
}

html.contrast * {
  background: black !important;
  color: yellow !important;
}

html.contrast * .MuiButtonBase-root, html.contrast * .MuiButtonBase-root * {
  background: gray !important;
  color: yellow !important;
}

html.contrast * .MuiTouchRipple-root {
  display: none !important;
}

html.contrast * .MuiInputBase-root fieldset {
  border: 1px solid yellow !important;
}

/*@media only screen and (max-width: 1366px) {*/
/*  html {*/
/*    !*font-size: calc(10px * 48 / 54);*!*/
/*    !*font-size: calc(10px * 32 / 48);*!*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 800px) {*/
/*  html {*/
/*    !*font-size: calc(10px * 48 / 54);*!*/
/*    font-size: calc(10px * 32 / 48);*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 375px) {*/
/*  html {*/
/*    !*font-size: calc(10px * 36 / 54);*!*/
/*    font-size: calc(10px * 32 / 48);*/
/*  }*/
/*}*/

body {
  margin: 0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: linear-gradient(107.56deg, #FFFFFF 0%, #F0F6FB 54.69%, #FFFFFF 100%);
  /*background: black !important;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


